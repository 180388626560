import React, { Component } from 'react';

class Now extends Component {
  render() {
    if(this.props.data){
      var projects = this.props.data.projects.map(function(projects){
        var projectImage = 'images/portfolio/'+projects.image;
        return <div key={projects.title} className="columns portfolio-item">
           <div className="item-wrap">
            <a href={projects.url} title={projects.title}>
               <img alt={projects.title} src={projectImage} />
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{projects.title}</h5>
                     <p>{projects.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
    }

    return (
      <section id="now">
      <div className="row">
         <div className="twelve columns main-col">
            <h2>/Now</h2>
            <p>
                Right now I'm primarily focused on launching a new generative AI application for a reboot of Whysaurus. <br/>
                Reach out to join the beta, or certainly if you're interested in contributing! <br />
                And most definitely <a href="https://www.whysaurus.com/">join in</a> the discussion!
            </p>
           <p>
               I'm also accepting ML consulting requests, particularly in the LLM/knowledge representation space. (Edtech specifically, but I'm open to anything with a social purpose.) <br/> 
               <br/>
               And I'm always interested in a novel mission on a full time basis as well, if you're a startup looking to change the world and in need of a seasoned ML engineer, I'd love to chat.
           </p>
          </div>
          <div className="twelve columns main-col">
            <h3>Project Links</h3>

            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {projects}
            </div>
          </div>
      </div>
      </section>
    );
  }
}

export default Now;
