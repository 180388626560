import React, { Component } from 'react';

class Header extends Component {
  render() {

    if(this.props.data){
        var emailYsMailto = "mailto:gene@whysaurus.com"
        var emailVcMailto = "mailto:gene@virtualcognition.com"
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}/></a></li>
      })
    }

    return (
      <header id="home">

      <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
          <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
	        <li><a className="smoothscroll" href="#now">Now</a></li>
            {/*<li><a className="smoothscroll" href="#portfolio">Projects</a></li>*/}
            <li><a className="smoothscroll" href="#about">About</a></li>
         </ul>
      </nav>

      <div className="row banner">
         <div className="banner-text">
            {/*<h1 className="responsive-headline">Hi. I'm Gene</h1>*/}
            <h3>Welcome.</h3>
	    <h3><a className="smoothscroll" href="#now">/now</a> - current work/thoughts.</h3>
            <h3><a className="smoothscroll" href="#about">About Me</a>.</h3>
{/*             <h3>Things I'm musing on: <a className="smoothscroll" href="#">Thoughts</a>.</h3> */}
            <h3><a href="https://www.whysaurus.com/">Whysaurus</a> - <a href={emailYsMailto}>Reach out</a> to collaborate!</h3>
            <h3>For Ego4D support/interest: <a href="https://ego4d-data.org/docs/">our docs</a>, <a href="https://github.com/facebookresearch/EGO4D/issues">github</a> or <a href="https://discuss.ego4d-data.org/">forum.</a></h3>
            <h3>And if you're looking to collaborate/muse on anything else, <a href={emailVcMailto}>don't hesitate!</a></h3>
            <hr />
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#now"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
