import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src="images/profilepic.jpg" alt="Profile Pic" />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>
                I've spent the past 20+ yr building interesting software systems at scale. <br />
                <br/>
                Most recently, as tech lead of <a href="https://ego4d-data.org/">Ego4D</a> and <a href="https://ego-exo4d-data.org/">Ego4D Ego-Exo</a> (the world's largest egocentric vision dataset) at FAIR (Meta AI), focused primarily on multimodal understanding and activity recognition. <br />
                <br/>
                I'm currently CTO of <a href="https://www.whysaurus.com/">Whysaurus</a>, a OSS project focused on building constructive argumentation online, and we're actively looking for contributors passionate in the space. <br />
                <br/>
                Prior to ML, I was a quantitative trader at one of the world's largest hedge funds and a bank for a dozen years of that,
                and previously had stints at Microsoft and several startups.  In university, my research was primarily on autonomous
                robotics and driving systems.
            </p>
         </div>
      </div>
   </section>
    );
  }
}

export default About;
