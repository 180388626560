import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Now from './Components/now';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      resumeData: {}
    };

    // ReactGA.initialize('UA-110570651-1');
    // ReactGA.pageview(window.location.pathname);
  }

  getResumeData = () => {
    fetch('/resumeData.json',{
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        })
      .then(response => {
        console.log(response);
        let obj = response.json();
        //this.setState({resumeData: obj});
        console.log(obj);
        return obj
      })
      .then(data => {
        console.log('fetch data:');
        console.log(data);
        this.setState({resumeData: data});
      });;
  }

  componentDidMount(){
    this.getResumeData();
  }

  render() {
    return (
      <div className="App">
        {/*<header className="App-header">*/}
        {/*  <img src={logo} className="App-logo" alt="logo" />*/}
        {/*</header>*/}
        <Header data={this.state.resumeData.main}/>
        <Now data={this.state.resumeData.portfolio} />
        {/*<Portfolio data={this.state.resumeData.portfolio}/>*/}
        <About data={this.state.resumeData.main}/>
        {/*<Resume data={this.state.resumeData.resume}/>*/}
        <Footer data={this.state.resumeData.main}/>
      </div>
    );
  }
}

export default App;
